import {
    ref,
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useStudentPayment() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const student_fees = ref([]); //ref: to make it reactive
    const student = ref([]); //ref: to make it reactive
    const childrens = ref([]); //ref: to make it reactive

    const getParentChildrens = async () => {
        try {
            let response = await axios.post('/api/get_parent_childrens');
            childrens.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }

    const getStudentPayments = async (user_id) => {
        try {
            let response = await axios.post('/api/students_payments/get_fees', { 'user_id': user_id });
            student_fees.value = response.data.data.fees;
            student.value = response.data.data.student;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }

    const payNow = async (installment_id, student_id) => {
        try {
            let response = await axios.post('/api/students_payments/pay_installment', { 'installment_id': installment_id, 'student_id': student_id });
            return response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const getUserRequest = async (id) => {
        try {
            let response = await axios.get('/api/user_requests/' + id);
            return response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const checkBankMisrPaymentStatus = async (merchant_ref_num) => {
        try {
            let response = await axios.post('/api/user_requests/bank_misr_trans_credit_card_check', { 'merchant_ref_num': merchant_ref_num });
            return response.data;
        } catch (e) {
            await store.dispatch("handleException", e);
            return 'fail';
        }
    }



    return {
        getParentChildrens,
        getStudentPayments,
        payNow,
        getUserRequest,
        checkBankMisrPaymentStatus,
        student_fees,
        student,
        childrens,
        msgs,
        errors,
    }
}