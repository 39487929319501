<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Student Payments</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">Students Payments</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">
              Student Name:
              <span v-if="student"
                >{{ student.firstname }} {{ student.middlename }}</span
              >
            </h4>
          </div>
          <div class="card-body">
            <div v-for="(fees, year) in student_fees" :key="year">
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: #781b20">
                  <i class="custom-left-border"></i> Study Year:
                  {{ year }}</label
                >
              </div>

              <div
                v-for="(installments, service) in fees"
                :key="service"
                class="table-responsive"
              >
                <span style="font-weight: 700">Fees Type: </span>
                {{ service }}

                <table
                  class="table table-bordered table-striped verticle-middle table-responsive-sm"
                >
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Start Payment Date</th>
                      <th scope="col">Due Payment Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Add</th>
                      <th scope="col">Deduct</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Action/Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(installment, k) in installments"
                      :key="k"
                    >
                      <td>{{ installment.id }}</td>
                      <td>{{ installment.start_date }}</td>
                      <td>{{ installment.deadline_date }}</td>
                      <td>{{ installment.amount }}</td>
                      <td>
                        {{
                          installment.added_fees ? installment.added_fees : 0
                        }}
                      </td>
                      <td>
                        {{
                          installment.deducted_fees
                            ? installment.deducted_fees
                            : 0
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(
                            installment.amount +
                              installment.added_fees -
                              installment.deducted_fees
                          )
                        }}
                      </td>
                      <td>
                        <button
                          v-if="
                            installment.have_to_pay &&
                            installment.paid_amount == 0
                          "
                          :disabled="disableBtn"
                          name="submit"
                          type="submit"
                          class="btn btn-primary"
                          value="Submit"
                          onclick="this.blur();"
                          @click.prevent="
                            payInstallmentNow(installment.id, student.id)
                          "
                        >
                          Pay Now
                        </button>
                        <span v-else-if="installment.paid_amount > 0">
                          Paid
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStudentPayment from "@/composables/student_payments";
import { inject, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  props: {
    user_id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasAnyRole = inject("hasAnyRole");
    const swal = inject("$swal");
    const router = useRouter();
    const store = useStore();
    const disableBtn = ref(false);

    const { getStudentPayments, payNow, student_fees, student, msgs, errors } =
      useStudentPayment();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasAnyRole("Parent,Student")) {
        router.push({ name: "401" });
      } else {
        getStudentPayments(props.user_id);
      }
    });

    const payInstallmentNow = async (installment_id, student_id) => {
      swal.fire({
        title: "Please wait while process your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });
      await payNow(installment_id, student_id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          store.dispatch("setUserPaymentRequest", resp);
          router.push({
            name: "Student-Payments-Payment-Confirmation",
            params: {
              userrequest_id: resp.id,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      disableBtn,
      student_fees,
      payInstallmentNow,
      student,
      errors,
      msgs,
    };
  },
};
</script>
